import React from "react";
import "../../styles/Impressum.css";
import Navbar2 from "../../components/Navbar2";
import Gallerie from "../../components/Gallerie";
import { Accordion } from "@mantine/core";
import { Row, Col } from 'antd';

function Impressum() {
  return (
    <>
      <Navbar2 />
      <Gallerie />
      <div id="Impressum">
      
      <Row>
      <Col span={6}>
            <div id="ImpressumBeschreibung1">
              <h3>FB Consulting & Trade GmbH</h3>
              <span>
                Unternehmensgegenstand: IT Dienstleistungen/ IT Consulting/ IT
                Projektmanagement UID-Nummer: ATU71991767 Firmenbuchnummer: FN
                466126 f Firmenbuchgericht: Wien Firmensitz: Neubaugasse 10/3a, 1070
                Wien Tel.: 0043 (1) 3055322 Fax: 0043 (0) 720348044 E-Mail:
                office@fbconsulting.co.at Mitglied bei: WKO, Landesinnung, etc.
                Berufsrecht: Gewerbeordnung: www.ris.bka.gv.at
                Aufsichtsbehörde/Gewerbebehörde: Magistratisches Bezirksamt des VII.
                Bezirkes Berufsbezeichnung: IT Consulting/Projektmanagement
                Verleihungsstaat: Österreich Angaben zur Online-Streitbeilegung:
                Verbraucher haben die Möglichkeit, Beschwerden an die
                OnlineStreitbeilegungsplattform der EU zu richten:
                http://ec.europa.eu/odr. Sie können allfällige Beschwerde auch an
                die oben angegebene E-Mail-Adresse richten. Quelle: Erstellt mit dem
                Impressum Generator von firmenwebseiten.at in Kooperation mit
                shoppingratgeber.at.
              </span>
            </div>
            <div id="ImpressumBeschreibung1">
              <h3>Haftungsausschluss</h3>
              <span>
                Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
                betrachten, von dem aus auf diese Webseite verwiesen wurde. Sofern
                Teile oder einzelne Formulierungen dieses Textes der geltenden
                Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen
                sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt
                und ihrer Gültigkeit davon unberührt. Die Inhalte unserer Seiten
                wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
                Vollständigkeit und Aktualität der Inhalte können wir jedoch keine
                Gewähr übernehmen. Als Diensteanbieter sind wir für eigene Inhalte
                auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Wir
                sind jedoch nicht verpflichtet, übermittelte oder gespeicherte
                fremde Informationen zu überwachen oder nach Umständen zu forschen,
                die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                Entfernung oder Sperrung der Nutzung von Informationen nach den
                allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche
                Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
                konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
                entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
                entfernen.
              </span>
            </div>
            <div id="ImpressumBeschreibung1">
              <h3>HAFTUNG FÜR INHALTE DIESER WEBSEITE</h3>
              <h3>HAFTUNG FÜR LINKS AUF WEBSEITEN DRITTER</h3>
              <span>
                Unser Angebot enthält Links zu externen Websites. Auf den Inhalt
                dieser externen Webseiten haben wir keinerlei Einfluss. Deshalb
                können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
                Für die Inhalte der verlinkten Seiten ist stets der jeweilige
                Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
                Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
                Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
                der Verlinkung nicht erkennbar. Eine permanente inhaltliche
                Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend entfernen.
              </span>
            </div>
            </Col>
            <Col span={6}>


        <div id="ImpressumBeschreibung1">
          <h3>URHEBERRECHT</h3>
          <span>
            Die Betreiber dieser Webseite sind bemüht, stets die Urheberrechte
            anderer zu beachten bzw. auf selbst erstellte sowie lizenzfreie
            Werke zurückzugreifen. Die durch die Seitenbetreiber erstellten
            Inhalte und Werke auf dieser Webseite unterliegen dem Urheberrecht.
            Beiträge Dritter sind als solche gekennzeichnet. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet.
          </span>
        </div>
        <div id="ImpressumBeschreibung1">
          <h3>Datenschutzerklärung</h3>
          <span>
            Wir legen großen Wert auf den Schutz Ihrer Daten. Um Sie in vollem
            Umfang über die Verwendung personenbezogener Daten zu informieren,
            bitten wir Sie die folgenden Datenschutzhinweise zur Kenntnis zu
            nehmen.
          </span>
        </div>
        <div id="ImpressumBeschreibung1">
          <h3>PERSÖNLICHE DATEN</h3>
          <span>
            Persönliche Daten, die Sie auf dieser Website elektronisch
            übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder
            andere persönlichen Angaben, werden von uns nur zum jeweils
            angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte
            weitergegeben. Der Provider erhebt und speichert automatisch
            Informationen am Webserver wie verwendeter Browser, Betriebssystem,
            Verweisseite, IP-Adresse, Uhrzeit des Zugriffs usw. Diese Daten
            können ohne Prüfung weiterer Datenquellen keinen bestimmten Personen
            zugeordnet werden und wir werten diese Daten auch nicht weiter aus
            solange keine rechtswidrige Nutzung unserer Webseite vorliegt.
          </span>
        </div>
        <div id="ImpressumBeschreibung1">
          <h3>FORMULARDATEN UND KOMMENTARE</h3>
          <span>
            Wenn Webseitenbesucher Kommentare oder Formulareinträge
            hinterlassen, werden die eingegebenen Daten und ihre IP-Adressen
            gespeichert. Das erfolgt zur Sicherheit, falls jemand
            widerrechtliche Inhalte verfasst (Beleidigungen, links- oder
            rechtsextreme Propaganda, Hasspostings usw.). In diesem Fall sind
            wir an der Identität des Verfassers interessiert.
          </span>
        </div>
        <div id="ImpressumBeschreibung1">
          <h3>COOKIES</h3>
          <span>
            Cookies sind kleine Dateien, die es dieser Webseite ermöglichen auf
            dem Computer des Besuchers spezifische, auf den Nutzer bezogene
            Informationen zu speichern, während unsere Website besucht wird.
            Cookies helfen uns dabei, die Nutzungshäufigkeit und die Anzahl der
            Nutzer unserer Internetseiten zu ermitteln, sowie unsere Angebote
            für Sie komfortabel und effizient zu gestalten. Wir verwenden
            einerseits Session-Cookies, die ausschließlich für die Dauer Ihrer
            Nutzung unserer Website zwischengespeichert werden und zum anderen
            permanente Cookies, um Informationen über Besucher festzuhalten, die
            wiederholt auf unsere Website zugreifen. Der Zweck des Einsatzes
            dieser Cookies besteht darin, eine optimale Benutzerführung anbieten
            zu können sowie Besucher wiederzuerkennen und bei wiederholter
            Nutzung eine möglichst attraktive Website und interessante Inhalte
            präsentieren zu können. Der Inhalt eines permanenten Cookies
            beschränkt sich auf eine Identifikationsnummer. Name, IP-Adresse
            usw. werden nicht gespeichert. Eine Einzelprofilbildung über Ihr
            Nutzungsverhalten findet nicht statt. Eine Nutzung unserer Angebote
            ist auch ohne Cookies möglich. Sie können in Ihrem Browser das
            Speichern von Cookies deaktivieren, auf bestimmte Webseiten
            beschränken oder Ihren Webbrowser (Chrome, IE, Firefox,…) so
            einstellen, dass er sie benachrichtigt, sobald ein Cookie gesendet
            wird. Sie können Cookies auch jederzeit von der Festplatte ihres PC
            löschen. Bitte beachten Sie aber, dass Sie in diesem Fall mit einer
            eingeschränkten Darstellung der Seite und mit einer eingeschränkten
            Benutzerführung rechnen müssen.
          </span>
        </div>
        </Col>
        <Col span={6}>


        <div id="ImpressumBeschreibung1">
          <h3>DATENSCHUTZERKLÄRUNG FÜR DIE NUTZUNG VON GOOGLE ANALYTICS</h3>
          <span>
            Diese Website benutzt Google Analytics, einen Webanalysedienst der
            Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“,
            Textdateien, die auf Ihrem Computer gespeichert werden und die eine
            Analyse der Benutzung der Website durch Sie ermöglichen. Die durch
            den Cookie erzeugten Informationen über Ihre Benutzung dieser
            Website werden in der Regel an einen Server von Google in den USA
            übertragen und dort gespeichert. Im Falle der Aktivierung der
            IP-Anonymisierung auf dieser Webseite, wird Ihre IP-Adresse von
            Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
            oder in anderen Vertragsstaaten des Abkommens über den Europäischen
            Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
            IP-Adresse an einen Server von Google in den USA übertragen und dort
            gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
            Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
            Reports über die Websiteaktivitäten zusammenzustellen und um weitere
            mit der Websitenutzung und der Internetnutzung verbundene
            Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im
            Rahmen von Google Analytics von Ihrem Browser übermittelte
            IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
            Sie können die Speicherung der Cookies durch eine entsprechende
            Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website vollumfänglich werden nutzen können. Sie
            können darüber hinaus die Erfassung der durch das Cookie erzeugten
            und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
            IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
            Google verhindern, indem sie das unter dem folgenden Link
            (http://tools.google.com/dlpage/gaoptout?hl=de) verfügbare
            Browser-Plugin herunterladen und installieren. Nähere Informationen
            zu Nutzungsbedingungen und Datenschutz finden Sie unter
            http://www.google.com/analytics/terms/de
            .html bzw. unter
            https://support.google.com/analytics/
            answer/6004245?hl=de. Wir
            weisen Sie darauf hin, dass auf dieser Webseite Google Analytics um
            den Code „gat._anonymizeIp();“ erweitert wurde, um eine
            anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu
            gewährleisten. Diese Webseite verwendet Google Maps für die
            Darstellung von Karteninformationen. Bei der Nutzung von Google Maps
            werden von Google auch Daten über die Nutzung der Maps-Funktionen
            durch Besucher der Webseiten erhoben, verarbeitet und genutzt.
            Nähere Informationen über die Datenverarbeitung durch Google können
            Sie den Datenschutzhinweisen von Google auf
            https://www.google.at/intl/de/policies/
            privacy/ entnehmen. Dort
            können Sie im Datenschutzcenter auch Ihre Einstellungen verändern,
            so dass Sie Ihre Daten verwalten und schützen können.
          </span>
        </div>
        </Col>
        <Col span={6}>

        <div id="ImpressumBeschreibung1">
          <h3>GOOGLE MAPS</h3>
          <h3>DATENSCHUTZERKLÄRUNG FÜR DIE NUTZUNG VON LINKEDIN</h3>
          <span>
            Unsere Webseite nutzt Funktionen des Social Media Netzwerks
            LinkedIn. Anbieter ist die LinkedIn Corporation, 2029 Stierlin
            Court, Mountain View, CA 94043, USA. Bei jedem Abruf einer unserer
            Webseiten, die Funktionen von LinkedIn enthält, wird eine Verbindung
            zu Servern von LinkedIn aufbaut. LinkedIn wird darüber informiert,
            dass Sie unsere Internetseiten mit Ihrer IP-Adresse besucht haben.
            Wenn Sie die Buttons von LinkedIn anklicken und in Ihrem Account bei
            LinkedIn eingeloggt sind, ist es LinkedIn möglich, Ihren Besuch auf
            unserer Internetseite Ihnen und Ihrem Benutzerkonto zuzuordnen. Wir
            weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis
            vom Inhalt der übermittelten Daten sowie deren Nutzung durch
            LinkedIn haben. Weitere Informationen hierzu finden Sie in der
            Datenschutzerklärung von LinkedIn unter
            https://www.linkedin.com/legal/privacy-policy.
          </span>
        </div>
        <div id="ImpressumBeschreibung1">
          <h3>DATENSCHUTZERKLÄRUNG FÜR DIE NUTZUNG VON XINGE</h3>
          <span>
            Unsere Webseite nutzt Funktionen des Social Media Netzwerks XING.
            Anbieter ist die XING AG, Dammtorstraße 29-32, 20354 Hamburg,
            Deutschland. Bei jedem Abruf einer unserer Seiten, die Funktionen
            von XING enthält, wird eine Verbindung zu Servern von XING
            hergestellt. XING wird möglicherweise darüber informiert, dass Sie
            unsere Internetseiten mit Ihrer IP-Adresse besucht haben. Wenn Sie
            die Buttons von XING anklicken und in Ihrem Account bei XING
            eingeloggt sind, ist es XING unter Umständen möglich, Ihren Besuch
            auf unserer Internetseite Ihnen und Ihrem Benutzerkonto zuzuordnen.
            Wir weisen darauf hin, dass wir als Anbieter der Seiten keine
            Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung
            durch XING haben. Weitere Information zum Datenschutz bei XING
            finden Sie in der Datenschutzerklärung unter
            https://www.xing.com/privacy.
          </span>
        </div>
        <div id="ImpressumBeschreibung1">
          <h3>DATENSCHUTZERKLÄRUNG FÜR GOOGLE ADSENSE</h3>
          <span>
            Diese Website benutzt Google AdSense, einen Dienst zum Einbinden von
            Werbeanzeigen der Google Inc. („Google“). Google AdSense verwendet
            sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert
            werden und die eine Analyse der Benutzung der Website ermöglicht.
            Google AdSense verwendet auch so genannte Web Beacons (unsichtbare
            Grafiken). Durch diese Web Beacons können Informationen wie der
            Besucherverkehr auf diesen Seiten ausgewertet werden. Die durch
            Cookies und Web Beacons erzeugten Informationen über die Benutzung
            dieser Website (einschließlich Ihrer IP-Adresse) und Auslieferung
            von Werbeformaten werden an einen Server von Google in den USA
            übertragen und dort gespeichert. Diese Informationen können von
            Google an Vertragspartner von Google weiter gegeben werden. Google
            wird Ihre IP-Adresse jedoch nicht mit anderen von Ihnen
            gespeicherten Daten zusammenführen. Sie können die Installation der
            Cookies durch eine entsprechende Einstellung Ihrer Browser Software
            verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem
            Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll
            umfänglich nutzen können. Durch die Nutzung dieser Website erklären
            Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch
            Google in der zuvor beschriebenen Art und Weise und zu dem zuvor
            benannten Zweck einverstanden. Weitere Informationen zur
            Datennutzung durch Google bei Ihrer Nutzung dieser Webseite erhalten
            Sie hier.
          </span>
        </div>
        <div id="ImpressumBeschreibung1">
          <h3>AUSKUNFTSRECHT</h3>
          <span>
            Sie haben jederzeit das Recht auf Auskunft über die bezüglich Ihrer
            Person gespeicherten Daten, deren Herkunft und Empfänger sowie den
            Zweck der Speicherung
          </span>
        </div>
        </Col>
        </Row>
      </div>
    </>
  );
}

export default Impressum;
