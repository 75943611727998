import React from "react";

function Video0() {
    React.useEffect(() => {
      var video = document.getElementById("myVideo");
      video.play();
  });
  
    return (
      <>
  
  <video video loop muted autoPlay controls = '' id="myVideo">
                  <source src={require("../assets/Hintergrund0.mp4")} type="video/mp4" />
                  Your browser does not support HTML5 video.
              </video>

              </>
  );
}

export default Video0;
