import React from "react";
import Navbar2 from "../../components/Navbar2";
import "../../styles/JobVorlage.css";
import JobVorlageB from "../../assets/JobVorlageB.jpg";
import Baris from "../../assets/Baris.jpeg";
import BewerberB from "../../assets/aboutBack.jpg";
import { Row, Col } from "antd";
import {
  EuroOutlined,
  CoffeeOutlined,
  DribbbleOutlined,
  HomeOutlined,
  LaptopOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

import { Image, Text, BackgroundImage, Center } from "@mantine/core";
import Video0 from "../../components/video0";

function Projektmanager() {
  return (
    <>
      <Navbar2 />
      <Video0 />
      <BackgroundImage className="JobBild" src={JobVorlageB}>
        <Center p="md">
          <Text className="JobVorlageText" color="#fff">
            Projektmanager
            <p>Wien | Vollzeit</p>
          </Text>
        </Center>
      </BackgroundImage>
      {/* ------------------------------------------------Links--------------------------------------------------------------------------------*/}
      <BackgroundImage className="WasMachtUns" src={BewerberB}>
        <Row className="Divider">
          <Col span={9} className="JobLinks">
            <ul align="left">
              <h2>Deine Aufgaben:</h2>
              <li>
                Planung, Steuerung und Controlling von
                IT-Softwareentwicklungsprojekten
              </li>
              <li>
                Führung des Projektteams, von IT-Partner/Lieferanten und der
                Stakeholder von der Erarbeitung der Anforderung bis zum
                Wartungsbetrieb
              </li>
              <li>
                Laufende Abstimmung mit den internen und externen
                Projektauftraggebern sowie anderen Projekten innerhalb eines
                Portfolios
              </li>
              <li>
                Beratung der AuftraggeberInnen über optimierte Lösung Szenarien
                in Abstimmung mit den Business AnalystInnen
              </li>
              <li>Erstellung von Projekt-Reports</li>
              <li>
                Definieren und umsetzen der Standards und Richtlinien für die
                Dokumentation
              </li>
            </ul>

            <ul align="left">
              <h2>Du begeisterst uns durch:</h2>
              <li>
                Abgeschlossenes Studium mit IT-Schwerpunkt oder gleichwertige
                Ausbildung
              </li>
              <li>Erste facheinschlägige Berufserfahrung ist von Vorteil</li>
              <li>
                Kenntnisse im IT-Anforderungsmanagement oder der
                Softwarearchitektur
              </li>
              <li>
                Fähigkeit komplexe Projekte zu strukturieren und zu managen
              </li>
              <li>
                Hohe Selbstständigkeit, Kommunikationsstärke und
                Durchsetzungsvermögen
              </li>
              <li>
                Sehr gute Deutsch- und Englischkenntnisse in Wort und Schrift
              </li>
            </ul>
            {/* --------------------------------------------Rechts------------------------------------------------------------------------------------*/}
          </Col>
          <Col span={9} className="Icons">
            <ul align="left">
              <h2>Was wir dir anbieten :</h2>
              <li>
                Gezielte Karriereplanung und ausgezeichnete
                Entwicklungsmöglichkeiten (bezahlte Zertifizierungen und
                Trainings)
              </li>
              <li>Attraktive Vergütung</li>
              <li>
                Öffentlich gut erreichbares Büro – mitten auf der Mariahilfer
                Straße
              </li>
              <li>Hands-on-Mentalität</li>
              <li>Mitarbeiterevents </li>
              <li>flache Hierarchien</li>
              <li>flexible Arbeitszeiten sowie Home-Office Möglichkeiten </li>
              <li>
                Free Drinks & Snacks nach deiner Wahl und weitere Benefits
              </li>
            </ul>

            <EuroOutlined />
            <CoffeeOutlined />
            <DribbbleOutlined />
            <HomeOutlined />
            <LaptopOutlined />

            <p>
              Für diese Position bieten wir in Ausmaß von 38,5 Stunden/Woche ein
              Jahresbruttogehalt ab 35.000,00 EUR. Je nach konkreter
              Qualifizierung und Erfahrung ist eine Bereitschaft zur Überzahlung
              gegeben.
            </p>
            <p>Bewerbung an: <a href="mailto:office@fb-c.at">office@fb-c.at</a></p>
          </Col>
        </Row>
      </BackgroundImage>

      {/* --------------------------------------------------------------------------------------------------------------------------------*/}

      {/* --------------------------------------------------------------------------------------------------------------------------------*/}
    </>
  );
}

export default Projektmanager;
