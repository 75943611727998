import React from 'react';
import { Menu } from 'antd';
import "../styles/Navbar.css";
import Logo from "../assets/fbcFuchs.png";
import { Link } from "react-scroll";
import { Dropdown, Button } from 'antd';

const überUns = (
  <Menu>
    <Menu.Item>
      <a target="/Team"  href="/Team">
        Team
      </a>
    </Menu.Item>
    <Menu.Item>
      <a   href="/WirUnterstutzen">
        Wir unterstützen
      </a>
    </Menu.Item>
    <Menu.Item>
      <a   href="/Zertifizierung">
        Zertifizierungen
      </a>
    </Menu.Item>
  </Menu>
);

const karriere = (
  <Menu>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="/Bewerber">
        BewerberPortal
      </a>
    </Menu.Item>
  </Menu>
);

const services = (
  <Menu>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="/Leistungen">
        Leistungen
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="/Termin">
        Termin Vereinbarung
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="/Partner">
        Unsere Partner
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="/Kunden">
        Kunden
      </a>
    </Menu.Item>
  </Menu>
);

const kontakt = (
  <Menu>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="/Impressum">
        Impressum
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="/DSGVO">
        DSGVO
      </a>
    </Menu.Item>
  </Menu>
);
function Navbar2() {
  return (
    <div className="Navbar">
      <div className="Navbar-header">
        <a href="/">
          <img src={Logo} />
        </a>
      </div>
      <div className="NavbarLinks">
        <a className="NavbarButtons" href="/"> HOME </a>
        <a className="NavbarButtons" href="/Bewerber"> KARRIERE </a>
        <a className="NavbarButtons" href="/Leistungen"> SERVICES </a>
        <a className="NavbarButtons" href="/Uber"> ÜBER UNS </a>
        <a className="NavbarButtons" href="/Jobs"> JOBS </a>
      </div>
    </div>


  );
}


export default Navbar2;