import React from "react";
import "../styles/About.css";
import {
  Card,
  Image,
  Text,
  Group,
  Spoiler,
} from "@mantine/core";
import JobB from "../assets/jobB.jpg";

function About() {
  return (
    <>
      <div
        id="aboutID"
        style={{
          backgroundImage: `url(${JobB})`,
          backgroundSize: "100% 100%",
          height: "auto",
          zIndex: 1,
        }}
      >
        <h3 className="ÜberUnsHome">Über uns</h3>
        <div className="FadeDownCards" id="example">
          <div
            className="CardsChange"
            style={{ width: "35%", margin: "0 2vh 0 0" }}
            data-aos="fade-down"
            data-aos-delay="250"
            data-aos-duration="400"
            data-aos-easing="ease-in-cubic"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor="#example"
          >
            <Card shadow="sm" padding="lg">
              <Card.Section>
                <Image
                  src={require("../assets/Card1.jpg")}
                  height={160}
                  alt="Norway"
                />
              </Card.Section>

              <div
                data-aos="fade-down"
                data-aos-delay="250"
                data-aos-duration="400"
                data-aos-easing="ease-in-cubic"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor="#example"
              >
                <Group
                  position="apart"
                  style={{ marginBottom: 5, marginTop: 5 }}
                >
                  <Text className="CardTitel">Unsere Kunden</Text>
                  <Text className="CardTitel2">

                  </Text>
                </Group>

                <Spoiler
                  maxHeight={120}
                  showLabel="Mehr Erfahren"
                  hideLabel="Verstecken"
                >
                  {
                    <Text size="sm" className="CardText">
                      Wir hatten in den vergangenen Jahren die Aufgabe eine
                      Vielzahl an unterschiedlichen Kunden mit Softwarelösungen
                      beliefern und noch mehr Kunden bei der Umsetzung ihrer
                      individuellen Projekte unterstützen zu dürfen. Auf der
                      nachfolgenden Seite finden sie einen Überblick unserer
                      Kunden und Projekte.
                    </Text>
                  }
                </Spoiler>
              </div>
            </Card>
          </div>
          <div
            className="CardsChange"
            style={{ width: "35%", margin: "0 0 0 0" }}
            data-aos="fade-down"
            data-aos-delay="500"
            data-aos-duration="400"
            data-aos-easing="ease-in-cubic"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor="#example"
          >
            <Card shadow="sm" padding="lg">
              <Card.Section>
                <Image
                  src={require("../assets/Card2.jpg")}
                  height={160}
                  alt="Norway"
                />
              </Card.Section>
              <div
                data-aos="fade-down"
                data-aos-delay="500"
                data-aos-duration="400"
                data-aos-easing="ease-in-cubic"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor="#example"
              >
                <Group
                  position="apart"
                  style={{ marginBottom: 5, marginTop: 5 }}
                >
                  <Text className="CardTitel">Leistungen</Text>
                  <Text className="CardTitel2">
                    
                  </Text>
                </Group>
                <Spoiler
                  maxHeight={120}
                  showLabel="Mehr Erfahren"
                  hideLabel="Verstecken"
                >
                  {
                    <Text size="sm" className="CardText">
                      Das Leistungsportfolio unseres Unternehmens erstreckt sich
                      über das gesamte Feld der Softwareentwicklung. Zu den
                      Hauptpfeilern unseres Angebots gehören
                      Softwarenentwicklung, Softwaretest und Projektmanagement.
                      Zögern sie bitte nicht uns zu kontaktieren, um mit uns
                      persönlich über ihre Bedürfnisse und Anforderungen zu
                      sprechen.
                    </Text>
                  }
                </Spoiler>
              </div>
            </Card>
          </div>
          {/* ------------------------------------------------------------------------------------------------------------------------------
          <div
            className="CardsChange"
            style={{ width: "25%", margin: "20vh 0 0 0" }}
            data-aos="fade-down"
            data-aos-delay="750"
            data-aos-duration="400"
            data-aos-easing="ease-in-cubic"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor="#example"
          >
            <Card shadow="sm" padding="lg">
              <Card.Section>
                <Image
                  src={require("../assets/Card4.jpg")}
                  height={160}
                  alt="Norway"
                />
              </Card.Section>
              <div
                data-aos="fade-down"
                data-aos-delay="750"
                data-aos-duration="400"
                data-aos-easing="ease-in-cubic"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor="#example"
              >
                <Group
                  position="apart"
                  style={{ marginBottom: 5, marginTop: 5 }}
                >
                  <Text className="CardTitel">Zertifizierungen</Text>
                  <Text className="CardTitel2">
                    <h5>Qualitätsstandards und Mitarbeiterentwicklung</h5>
                  </Text>
                </Group>
                <Spoiler
                  maxHeight={120}
                  showLabel="Mehr Erfahren"
                  hideLabel="Verstecken"
                >
                  {
                    <Text size="sm" className="CardText">
                      Die Entwicklung und Weiterbildung unserer Mitarbeiter und
                      die Erfüllung höchster Qualitätsstandards sind wesentliche
                      Augenmerke unserer Unternehmensphilosophie. Aus diesem
                      Grund sind wir stehts bemüht unsere Mitarbeiter mit den
                      führenden Zertifizierungen in ihrem jeweiligen
                      Kompetenzfeld auszustatten. So sind unter anderen unsere
                      Tester nach ISTQB und unsere Projektmanager nach IPMA
                      zertifiziert.
                    </Text>
                  }
                </Spoiler>
              </div>
            </Card>
          </div>
                ----------------------------------------------------------------------------------------------------------------------------*/}
        </div>
      </div>
    </>
  );
}

export default About;
