import React from "react";
import Navbar2 from "../../components/Navbar2";
import "../../styles/JobVorlage.css";
import JobVorlageB from "../../assets/JobVorlageB.jpg";
import Baris from "../../assets/Baris.jpeg";
import BewerberB from "../../assets/aboutBack.jpg";
import { Row, Col } from "antd";
import {
  EuroOutlined,
  CoffeeOutlined,
  DribbbleOutlined,
  HomeOutlined,
  LaptopOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

import { Image, Text, BackgroundImage, Center } from "@mantine/core";
import Video0 from "../../components/video0";

function SoftwareentwicklerJ() {
  return (
    <>
      <Navbar2 />
      <Video0 />
      <BackgroundImage className="JobBild" src={JobVorlageB}>
        <Center p="md">
          <Text className="JobVorlageText" color="#fff">
            Java Softwareentwickler
            <p>Wien | Vollzeit</p>
          </Text>
        </Center>
      </BackgroundImage>
      {/* ------------------------------------------------Links--------------------------------------------------------------------------------*/}
      <BackgroundImage className="WasMachtUns" src={BewerberB}>
        <Row className="Divider">
          <Col span={9} className="JobLinks">
            <ul align="left">
              <h2>Deine Aufgaben:</h2>
              <li>
                Design und Entwicklung von State-of-the-Art Enterprise Software
                in Java
              </li>
              <li>Mitarbeit in verschiedenen IT Projekten beim Kunden</li>
              <li>
                Regelmäßiger Austausch und enge Zusammenarbeit mit den anderen
                Projektmitgliedern{" "}
              </li>
            </ul>

            <ul align="left">
              <h2>Du begeisterst uns durch:</h2>
              <li>
                Abgeschlossenes Studium mit IT-Schwerpunkt oder gleichwertige
                Ausbildung
              </li>
              <li>Mindestens 2 Jahre Berufserfahrung als Java Entwickler</li>
              <li>
                Kenntnisse in Java 8 EE, Springboot, Hibernate, Oracle DB, REST
                API, Maven, Azure DevOps, Git Repository, MySQL
              </li>
              <li>Entwicklungsumgebungen IntelliJ, VS Code</li>
              <li>Genaue und sorgfältige Arbeitsweise</li>
              <li>
                Sehr gute Deutsch- und Englischkenntnisse in Wort und Schrift
              </li>
            </ul>
            {/* --------------------------------------------Rechts------------------------------------------------------------------------------------*/}
          </Col>
          <Col span={9} className="Icons">
            <ul align="left">
              <h2>Was wir dir anbieten :</h2>
              <li>
                Gezielte Karriereplanung und ausgezeichnete
                Entwicklungsmöglichkeiten (bezahlte Zertifizierungen und
                Trainings)
              </li>
              <li>Attraktive Vergütung</li>
              <li>
                Öffentlich gut erreichbares Büro – mitten auf der Mariahilfer
                Straße
              </li>
              <li>Hands-on-Mentalität</li>
              <li>Mitarbeiterevents </li>
              <li>flache Hierarchien</li>
              <li>flexible Arbeitszeiten sowie Home-Office Möglichkeiten </li>
              <li>
                Free Drinks & Snacks nach deiner Wahl und weitere Benefits
              </li>
              <li>
                State-of-Art Entwicklernotebooks werden zur Verfügung gestellt
              </li>
              <li>Bis zu 100% Homeoffice möglich</li>
            </ul>

            <EuroOutlined />
            <CoffeeOutlined />
            <DribbbleOutlined />
            <HomeOutlined />
            <LaptopOutlined />

            <p>
              Für diese Position bieten wir eine Bezahlung nach dem
              IT-Kollektivvertrag. Je nach konkreter Qualifizierung und
              Erfahrung ist eine Bereitschaft zur Überzahlung gegeben.
            </p>
            <p>Bewerbung an: <a href="mailto:office@fb-c.at">office@fb-c.at</a></p>
          </Col>
        </Row>
      </BackgroundImage>

      {/* --------------------------------------------------------------------------------------------------------------------------------*/}

      {/* --------------------------------------------------------------------------------------------------------------------------------*/}
    </>
  );
}

export default SoftwareentwicklerJ;
